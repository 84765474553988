const qs = sel => document.querySelector(sel);
const qsa = sel => document.querySelectorAll(sel);
const APP = {
	name: 'iBrush HTML Starter'
};
$(function() {
    svg4everybody();

    $('.js-popup-link').magnificPopup({
      items: {
            src: '.popup',
            type: 'inline'
        }
    });

    // $('.image-link').magnificPopup({
    //   type:'image',
    // });
});
